<template>
  <div class="app-container">
    <div id="app1">
      <div class="content">
        <div class="detail">
          <span class="title mr20">{{ shopname }}</span>
          <span class="mr20">摄像头编号：{{ cameraid }}</span>
          <span class="mr20">MAC-ID：{{ macid }}</span>
        </div>
        <div class="live">
          <video id="my-player" autoplay width="800" height="600">
            <source type="application/x-mpegURL" />
          </video>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import flvjs from 'flv.js/dist/flv.js';
import { getoptCamera } from 'src/api/legacy'

export default {
  data() {
    return {
      shopname: '',
      cameraid: '',
      macid: '',
      copycontent: '',
      src: '',
      //录播
      sec: 60,
    }
  },
  mounted() {
    this.cameraid = this.$route.query.cameraid
    this.getoptCamera()
  },
  methods: {
    getReplayUrl(url) {
      const VIDEO_APP_ID = '1255830500'
      if (url.includes('?cameraid=')) {
        return `${url}&seek=${this.sec}`
      } else {
        return `${url}?appid=${VIDEO_APP_ID}&delay=${this.sec}`
      }
    },
    getoptCamera() {
      let data = {
        cameraid: this.cameraid,
      }
      getoptCamera(data).then((res) => {
        if (res && res.status === 0) {
          this.cameraid = res.data.cameraid
          this.macid = res.data.macid
          // this.src = this.getReplayUrl(res.data.boxRealtimeUrl);
          this.src = res.data.boxRealtimeUrl
          this.shopname = res.data.shopname
          // var player = videojs('my-player', {
          //   techOrder: ["html5"]
          // });
          // setTimeout(() => {
          //   player.src(this.src);
          //   player.play();
          // }, 100)

          // 待重构
            if (flvjs.isSupported()) {
              var videoElement = document.getElementById('my-player')
              var flvPlayer = flvjs.createPlayer({
                type: 'flv',
                url: this.src,
              })
              flvPlayer.attachMediaElement(videoElement)
              flvPlayer.load()
              flvPlayer.play()
            }
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.containerX .contentX {
  padding: 61px 0 40px;
}
.headmenu {
  padding: 0 20px;
  height: 70px;
  line-height: 70px;
  font-size: 14px;
  border-bottom: 1px solid #d1d1d1;
}

.headmenu a {
  color: #5c76cc;
}

.detail {
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #d1d1d1;
  margin-bottom: 20px;
}

.title {
  font-size: 16px;
  font-weight: 700;
}

.vjs-error-display.vjs-modal-dialog {
  display: none;
}
</style>